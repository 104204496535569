import Login_Form from "../components/Forms/Formulaires/LoginForm";
import appico from "../assets/hopteo_manager.webp";
import BackToHomeLink from "../components/navigation/BackToHomeLink";

const Login = () => {
	return (
		<div className="home-page page-container loginpage auth-page">
			<img src={appico} className={"logo-container"} />
			<div className="left home">
				<main>
					<BackToHomeLink/>

					<h1 className="bigtitle">Connectez-vous.</h1>
					<p>Commencer à utiliser vos avantages dès maintenant !</p>
				</main>
			</div>
			<div className="form-container">
				<Login_Form />
			</div>
		</div>
	);
};

export default Login;
