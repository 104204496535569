
import React from "react";

const FormSubmitButton = ({ children, isPending, ...props }) => {
	return (
		<button
			type="submit"
			disabled={isPending}
			style={{
				backgroundColor: "var(--orange)",
				fontFamily:"Inter-SemiBold",
				color: "white",
				border: "0",
				fontSize: "1rem",
				padding: "10px 20px",
				boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
				cursor: isPending ? "not-allowed" : "pointer",
				opacity: isPending ? 0.7 : 1,
			}}
			{...props}
		>
			{isPending ? "Chargement..." : children}
		</button>
	);
};

export default FormSubmitButton;
