import "./row.css";

export const Row = ({ label, value }) => {

	return (
		<div className={"row-text"}>
			<p>{label}</p>
			<p>{value}</p>
		</div>
	);
};
