import Register_Form from "../components/Forms/Formulaires/Register-form";
import appico from "../assets/hopteo_manager.webp";
import BackToHomeLink from "../components/navigation/BackToHomeLink";

const Register = () => {
	return (
		<div className="home-page page-container loginpage auth-page">
			<img
				src={appico}
				style={{
					width: 250,
					position: "absolute",
					top: 50,
					left: 80
				}}
			/>
			<div className="left home">
				<main>
					<BackToHomeLink/>

					<h1 className="bigtitle">Rejoignez la file d’attente.</h1>
					<p>Faites partie des premières écoles à profiter des avantages de Hopteo Manager !</p>
				</main>
			</div>
			<div className="form-container">
				<Register_Form />
			</div>
		</div>
	);
};

export default Register;
