import React, { useState } from "react";
import LandingPopUpForm from "../../components/Forms/Formulaires/LandingPopUpForm";
import BackToHomeLink from "../../components/navigation/BackToHomeLink";

const LandingPopUp = () => {
	// Récupère la préférence de l'utilisateur depuis le localStorage
	const userPreference = localStorage.getItem("popup-hidden");
	const [show, setShow] = useState(userPreference === null);

	// Fonction pour cacher le popup et enregistrer la préférence dans le localStorage
	const hidePopup = () => {
		setShow(false);
		localStorage.setItem("popup-hidden", "true");
	};

	// Si le popup ne doit pas être affiché, on ne retourne rien
	if (!show) {
		return null;
	}

	return (
		<div className="landing-popup">
			<div className="popup-content">
				<BackToHomeLink onClick={hidePopup} fontSize={"1rem"} iconSize={20} />
				<div style={{marginTop:10, marginBottom:30}}>
					<h1 style={{marginBottom:5}}>Testez gratuitement <br/>Hopteo Manager</h1>
					<p>Rejoignez la file d'attente et faites partie des premières écoles à profiter des avantages d'Hopteo Manager</p>
				</div>
				<LandingPopUpForm setShow={hidePopup} />
			</div>
		</div>
	);
};

export default LandingPopUp;
