import "./style.css";
import convert from "./icons/f.svg";
import hat from "./icons/hat.svg";
import chat from "./icons/chat.svg";
import stat from "./icons/stat.svg";
import { Home } from "../../components/LandingComponents/home/Home";
import { App } from "../../components/LandingComponents/App/App";
import { NavigationBar } from "../../components/LandingComponents/navBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { Advantages } from "../../components/LandingComponents/Advantages/Advantages";
import { Partners } from "../../components/LandingComponents/Partners/Partners";
import { Communication } from "../../components/LandingComponents/Communications/Communication";
import { Leads } from "../../components/LandingComponents/Leads/Leads";
import { Contact } from "../../components/LandingComponents/Contact/Contact";
import { Supports } from "../../components/LandingComponents/Supports/Supports";
import LandingPopUp from "./PopUp";

export const LandingPage = () => {
	return (
		<>
			<main className={"landing"}>
				<LandingPopUp/>
				<NavigationBar />
				<Home />
				<div>
					<div id={"home-band"}>
						<div>
							<img src={convert} alt={"e-reputation"} />
							Améliorez votre e-réputation
						</div>
						<div>
							<img src={hat} alt={"valoriser formations"} />
							Valorisez vos formations
						</div>
						<div>
							<img src={stat} alt={"tendances"} />
							Analysez les tendances
						</div>
						<div>
							<img src={chat} alt={"contact étudiants"} />
							Restez au contact des étudiants
						</div>
					</div>
				</div>
				<Partners />
				<App />
				<Advantages />
				<Communication />
				<Leads />
				<Contact />
				<Supports />
			</main>
			<Footer />
		</>
	);
};
