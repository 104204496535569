import { Icon } from "../../Icons/Icon";
import { Link } from "react-router-dom";

const BackToHomeLink = ({ onClick , fontSize="1.2rem", iconSize=30}) => {
	return (
		<Link
			to="/"
			onClick={(e) => {
				if (onClick) {
					e.preventDefault(); // Empêche la navigation immédiate si onClick est fourni
					onClick();
				}
			}}
			style={{
				fontSize: fontSize,
				fontFamily: "Inter-SemiBold",
				display: "flex",
				flexDirection: "row",
				flexWrap: "nowrap",
				alignItems: "center",
				textDecoration: "none",
				color: "var(--orange)",
				gap: "5px",
			}}
		>
			<Icon size={iconSize} name={"arrow-left"} color={"var(--orange)"} />
			Retour à l'accueil
		</Link>
	);
};

export default BackToHomeLink;
