import React from "react";
import "../form.css";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import authService from "../../../services/client/clientService";
import { AuthInput } from "../Input/AuthInput";
import { Icon } from "../../Icons/Icon";
import FormSubmitButton from "../Input/FormSubmitButton";

const ResetForm = ({ token }) => {
	// Définition du schéma de validation avec Yup
	const schema = yup
		.object({
			password: yup
				.string()
				.required("Le mot de passe est requis.")
				.min(8, "Le mot de passe doit contenir au moins 8 caractères."),
			confirmPassword: yup
				.string()
				.oneOf([yup.ref("password"), null], "Les mots de passe ne correspondent pas.")
				.required("La confirmation du mot de passe est requise.")
		})
		.required();

	// Initialisation du formulaire avec react-hook-form
	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	});

	// Utilisation du service d'authentification pour réinitialiser le mot de passe
	const { mutateAsync, error, isPending } = authService.useResetPassword();

	// Fonction appelée lors de la soumission du formulaire
	const onSubmit = async data => {
		try {
			// Envoi du token et du nouveau mot de passe au backend
			await mutateAsync({ token, password: data.password });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="login">
			<form onSubmit={handleSubmit(onSubmit)}>
				<Link to={"/"} className="responsive-back">
					<Icon size={30} name={"arrow-left"} color={"var(--orange)"} />
					Retour à l'accueil
				</Link>

				{/* Champ pour le nouveau mot de passe */}
				<Controller
					name="password"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.password}
							type={"password"}
							label={"Nouveau mot de passe"}
							required={true}
						/>
					)}
				/>

				{/* Champ pour la confirmation du mot de passe */}
				<Controller
					name="confirmPassword"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<AuthInput
							field={field}
							error={errors.confirmPassword}
							type={"password"}
							label={"Confirmez le mot de passe"}
							required={true}
						/>
					)}
				/>

				{/* Affichage des erreurs éventuelles */}
				{error && (
					<p className="error" style={{ position: "relative", marginBottom: 10 }}>
						{error?.message}
					</p>
				)}

				<FormSubmitButton isPending={isPending}>Réinitialiser le mot de passe</FormSubmitButton>
				{/* Lien pour retourner à la connexion */}
				<div className="redirect">
					<p>Vous vous souvenez du mot de passe ?</p>
					<Link to="/login">Se connecter</Link>
				</div>
			</form>
		</div>
	);
};

export default ResetForm;
